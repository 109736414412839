<template>
  <div class="sticky">
    <div class="text-black w-full px-10 flex justify-between items-center">
      <div v-if="onboarding_kit_id" class="font-semibold text-xl relative">
        <div>
          <KitName
            :onboarding_kit_id="onboarding_kit_id"
            :kit_name="kit_name"
          />
        </div>
      </div>
      <div class="flex flex-col md:flex-row place-content-end">
        <div v-show="error" class="text-red-500 place-self-end p-3">
          <p class="text-md font-semibold">{{ error }}</p>
        </div>
        <div
          v-show="success"
          class="success text-green-500 place-self-end text-right"
        >
          <p class="text-sm md:text-base font-semibold">
            {{ success }}
          </p>
          <p class="text-sm text-gray-400">
            You will be redirected automatically . . .
          </p>
        </div>
        <div
          class="bg-white border border-nColorBlue text-nColorBlue flex items-center px-5 rounded-lg cursor-pointer ml-2 uppercase"
          @click.prevent="addMoreExperiences"
        >
          Add more experiences
        </div>
        <ButtonInput
          buttonText="Save Kit"
          :loading="loading"
          :buttonClass="
            'transition shadow duration-300 ease-out tracking-wider bg-nButtonGreen text-white w-24 py-2 rounded-lg uppercase font-semibold ml-4 outline-none focus:outline-none'
          "
          :loadingClass="
            'cursor-not-allowed transition shadow duration-300 ease-out tracking-wider bg-nButtonGreen text-white w-24 py-2 rounded-lg uppercase font-semibold ml-4 outline-none focus:outline-none'
          "
          @click.prevent="saveKit"
        />
      </div>
    </div>
    <ConfirmationModal
      @closeModal="modal.show = false"
      @save="saveKit"
      :modal="modal"
    />
  </div>
</template>

<script>
import KitName from "@/components/OnboardingKit/KitComponents/KitName";
import ConfirmationModal from "@/components/Modals/ConfirmationModal.vue";
import ButtonInput from "@/components/Elements/ButtonInput.vue";

export default {
  props: {
    kit_name: { type: String },
    onboarding_kit_id: { type: String },
    showSaveButton: { type: Boolean },
    errorMsg: { type: String },
    successMsg: { type: String },
    loading: { type: Boolean },
    saveButtonEnabled: { type: Boolean, default: () => true },
  },
  components: {
    ConfirmationModal,
    KitName,
    ButtonInput,
  },
  created() {},
  mounted() {},
  data() {
    return {
      error: this.errorMsg,
      success: this.successMsg,
      saveButtonStatus: this.saveButtonEnabled,
      modal: {
        show: false,
        heading: "Save kit",
        question: "Are you sure you want to save this onboarding kit?",
        action: "save",
        showLottie: true,
        lottiePath: "lotties/save.json",
      },
    };
  },
  methods: {
    updateSuccessErrorMsgs(successMsg, errorMsg) {
      this.success = successMsg;
      this.error = errorMsg;
    },
    updateEnableAddButton(status) {
      this.addButtonStatus = status;
    },
    addExperienceToKit() {
      if (this.addButtonStatus) {
        this.$emit("addExperienceToKit");
      }
    },
    showRemoveModal() {
      this.modal.show = true;
    },
    saveKit() {
      this.$emit("saveKit");
    },
    addMoreExperiences() {
      this.$router.push({
        name: "CreateOnboardingKitCatalog",
        params: {
          onboarding_kit_id: this.onboarding_kit_id,
          tag: "all",
        },
      });
    },
  },
  computed: {},
  watch: {
    saveButtonEnabled(newsaveButtonEnabled, oldsaveButtonEnabled) {
      if (oldsaveButtonEnabled != newsaveButtonEnabled) {
        this.saveButtonStatus = this.saveButtonEnabled;
      }
    },
  },
};
</script>
<style scoped lang="postcss">
div.sticky {
  position: -webkit-sticky;
  position: sticky;
  height: 80px;
  top: -16px;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
div.sticky::before,
div.sticky::after {
  content: "";
  display: block;
  height: 16px;
  /* make pseudo elements sticky as well */
  position: sticky;
  -webkit-position: sticky;
}

/* SHADOW */
div.sticky::before {
  top: 48px; /* shadow is at bottom of element, so at 48 + 16 = 64px */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
}

/* COVER */
div.sticky::after {
  /* linear gradient from background color to transparent acts as
     a transition effect so the shadow appears gradually */
  background: linear-gradient(
    white 10%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(255, 255, 255, 0.4) 70%,
    transparent
  );
  top: 0;
  /* cover should fall over shadow */
  z-index: 2;
}
div.sticky > div {
  background: white;
  height: 64px;
  position: sticky;
  -webkit-position: sticky;
  top: 0px;
  /* compensate for shadow with negative margin */
  margin-top: -8px;
  /* content should fall over shadow and cover */
  z-index: 3;
}
</style>
