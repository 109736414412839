<template>
  <div class="mx-auto relative ml-2.5" v-if="experiences">
    <Navbar class="px-10" />
    <ActionBar
      ref="expActionBar"
      :onboarding_kit_id="onboarding_kit_id"
      :kit_name="kitName"
      :errorMsg="errorMsg"
      :successMsg="successMsg"
      :loading="loading"
      @saveKit="saveKit()"
    />
    <div class="flex gap-4 mx-10">
      <div
        class="bg-white border border-nColorStrokeGray rounded-xl text-black w-full mt-0 px-4 py-2 flex font-semibold text-lg items-center gap-4"
      >
        <div>All experiences will be delivered at</div>
        <div class="w-40">
          <Multiselect
            placeholder="Delivery time"
            ref="multiselect"
            :canClear="false"
            :caret="true"
            class="multiselect-box"
            :options="
              deliveryTimes.map((deliveryTime) => ({
                value: deliveryTime.id,
                label: deliveryTime.name,
              }))
            "
            v-model="deliveryTime"
          />
        </div>
      </div>
      <div
        class="bg-white border border-nColorStrokeGray rounded-xl text-black w-full mt-0 px-4 py-2 flex font-semibold text-lg items-center"
      >
        Experiences will be delivered in equal frequency between 2 milestone
        dates.
      </div>
    </div>
    <div id="candidateExperiences" class="mx-10 my-4">
      <div id="ce-heading" class="text-black font-semibold text-lg">
        Candidate Experiences
      </div>
      <div
        id="ce-experiences"
        class="bg-white border border-nColorStrokeGray rounded-xl text-black w-full mt-0 px-2 py-6 grid gap-0.5"
        v-if="candidateExps"
      >
        <div v-if="candidateExps.length > 0">
          <div class="px-4 pb-2 text-gray-500">
            Drag and rearrange experiences in any order.
          </div>
          <draggable v-model="candidateExps" class="flex">
            <template v-slot:item="{ item }">
              <div class="mx-4 relative">
                <img :src="item.icon" class="w-52 rounded-lg" />
                <div class="absolute top-2 right-2">
                  <div class="flex gap-1">
                    <img
                      :src="require('@/assets/images/catalog/edit.svg')"
                      class="cursor-pointer focus:outline-none"
                      @click.prevent="
                        saveProgress(
                          'edit',
                          slugsConfig.expJourneys.candidateExperiences,
                          item.id
                        )
                      "
                    />
                    <img
                      :src="require('@/assets/images/catalog/remove.svg')"
                      class="cursor-pointer focus:outline-none"
                      @click.prevent="
                        showRemoveModal(
                          slugsConfig.expJourneys.candidateExperiences,
                          slugsConfig.expJourneyNames.candidateExperiences,
                          item.id
                        )
                      "
                    />
                  </div>
                </div>
              </div>
              <div class="flex justify-center mt-2 gap-2 items-center">
                <div class="w-full">
                  <div
                    :class="{ hidden: candidateExps.indexOf(item) === 0 }"
                    class="border-b border-dashed border-gray-400"
                  ></div>
                </div>
                <div><div class="bg-black w-2 h-2 rounded-full"></div></div>
                <div class="w-full">
                  <div
                    :class="{
                      hidden:
                        candidateExps.indexOf(item) ===
                        candidateExps.length - 1,
                    }"
                    class="border-b border-dashed border-gray-400"
                  ></div>
                </div>
              </div>
              <div class="w-full text-center mt-2 font-medium">
                {{ item.name }}
              </div>
            </template>
          </draggable>
        </div>
        <div v-else>
          <div class="px-4 pb-2 text-gray-500">
            There are no experiences here.
          </div>
        </div>
      </div>
    </div>
    <div id="fdawExperiences" class="mx-10 my-4">
      <div id="fdaw-heading" class="text-black font-semibold text-lg">
        First Day At Work Experiences
      </div>
      <div
        id="fdaw-experiences"
        class="bg-white border border-nColorStrokeGray rounded-xl text-black w-full mt-0 px-2 py-6 grid gap-0.5"
        v-if="fdawExps"
      >
        <div v-if="fdawExps.length > 0">
          <div class="px-4 pb-2 text-gray-500">
            Drag and rearrange experiences in any order.
          </div>
          <draggable v-model="fdawExps" class="flex flex-wrap">
            <template v-slot:item="{ item }">
              <div class="mx-4 relative">
                <img :src="item.icon" class="w-52 rounded-lg" />
                <div class="absolute top-2 right-2">
                  <div class="flex gap-1">
                    <img
                      :src="require('@/assets/images/catalog/edit.svg')"
                      class="cursor-pointer focus:outline-none"
                      @click.prevent="
                        saveProgress(
                          'edit',
                          slugsConfig.expJourneys.fdawExperiences,
                          item.id
                        )
                      "
                    />
                    <img
                      :src="require('@/assets/images/catalog/remove.svg')"
                      class="cursor-pointer focus:outline-none"
                      @click.prevent="
                        showRemoveModal(
                          slugsConfig.expJourneys.fdawExperiences,
                          slugsConfig.expJourneyNames.fdawExperiences,
                          item.id
                        )
                      "
                    />
                  </div>
                </div>
              </div>
              <div class="flex justify-center mt-2 gap-2 items-center">
                <div class="w-full">
                  <div
                    :class="{ hidden: fdawExps.indexOf(item) === 0 }"
                    class="border-b border-dashed border-gray-400"
                  ></div>
                </div>
                <div><div class="bg-black w-2 h-2 rounded-full"></div></div>
                <div class="w-full">
                  <div
                    :class="{
                      hidden: fdawExps.indexOf(item) === fdawExps.length - 1,
                    }"
                    class="border-b border-dashed border-gray-400"
                  ></div>
                </div>
              </div>
              <div class="w-full text-center mt-2 font-medium">
                {{ item.name }}
              </div>
            </template>
          </draggable>
        </div>
        <div v-else>
          <div class="px-4 pb-2 text-gray-500">
            There are no experiences here.
          </div>
        </div>
      </div>
    </div>
    <div id="employeeExperiences" class="mx-10 my-4">
      <div id="emp-heading" class="text-black font-semibold text-lg">
        Employee Experiences
      </div>
      <div
        id="emp-experiences"
        class="bg-white border border-nColorStrokeGray rounded-xl text-black w-full mt-0 px-2 py-6 grid gap-0.5"
        v-if="employeeExps"
      >
        <div v-if="employeeExps.length > 0">
          <div class="px-4 pb-2 text-gray-500">
            Drag and rearrange experiences in any order.
          </div>
          <draggable v-model="employeeExps" class="flex flex-wrap">
            <template v-slot:item="{ item }">
              <div class="mx-4 relative">
                <img :src="item.icon" class="w-52 rounded-lg" />
                <div class="absolute top-2 right-2">
                  <div class="flex gap-1">
                    <img
                      :src="require('@/assets/images/catalog/edit.svg')"
                      class="cursor-pointer focus:outline-none"
                      @click.prevent="
                        saveProgress(
                          'edit',
                          slugsConfig.expJourneys.employeeExperiences,
                          item.id
                        )
                      "
                    />
                    <img
                      :src="require('@/assets/images/catalog/remove.svg')"
                      class="cursor-pointer focus:outline-none"
                      @click.prevent="
                        showRemoveModal(
                          slugsConfig.expJourneys.employeeExperiences,
                          slugsConfig.expJourneyNames.employeeExperiences,
                          item.id
                        )
                      "
                    />
                  </div>
                </div>
              </div>
              <div class="flex justify-center mt-2 gap-2 items-center">
                <div class="w-full">
                  <div
                    :class="{ hidden: employeeExps.indexOf(item) === 0 }"
                    class="border-b border-dashed border-gray-400"
                  ></div>
                </div>
                <div><div class="bg-black w-2 h-2 rounded-full"></div></div>
                <div class="w-full">
                  <div
                    :class="{
                      hidden:
                        employeeExps.indexOf(item) === employeeExps.length - 1,
                    }"
                    class="border-b border-dashed border-gray-400"
                  ></div>
                </div>
              </div>
              <div class="w-full text-center mt-2 font-medium">
                {{ item.name }}
              </div>
            </template>
          </draggable>
        </div>
        <div v-else>
          <div class="px-4 pb-2 text-gray-500">
            There are no experiences here.
          </div>
        </div>
      </div>
    </div>
    <div id="scheduledExperiences" class="mx-10 my-4">
      <div id="scheduled-heading" class="text-black font-semibold text-lg">
        Scheduled Experiences
      </div>
      <div
        id="scheduled-experiences"
        class="bg-white border border-nColorStrokeGray rounded-xl text-black w-full mt-0 px-2 py-6 grid gap-0.5"
      >
        <div v-if="scheduledExps">
          <div class="px-4 pb-2 text-gray-500">
            These experiences are scheduled for an employee post onboarding.
          </div>
          <div class="flex gap-4">
            <div v-for="item in scheduledExps" :key="item.id" class="flex">
              <div class="mx-4 relative">
                <img :src="item.icon" class="w-52 rounded-lg" />
                <div class="absolute top-2 right-2">
                  <div class="flex gap-1">
                    <img
                      :src="require('@/assets/images/catalog/edit.svg')"
                      class="cursor-pointer focus:outline-none"
                      @click.prevent="
                        saveProgress(
                          'edit',
                          slugsConfig.expJourneys.employeeExperiences,
                          item.id
                        )
                      "
                    />
                    <img
                      :src="require('@/assets/images/catalog/remove.svg')"
                      class="cursor-pointer focus:outline-none"
                      @click.prevent="
                        showRemoveModal(
                          slugsConfig.expJourneys.employeeExperiences,
                          slugsConfig.expJourneyNames.employeeExperiences,
                          item.id
                        )
                      "
                    />
                  </div>
                </div>
                <div class="w-full text-center mt-2 font-medium">
                  {{ item.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="px-4 pb-2 text-gray-500">
            There are no experiences here.
          </div>
        </div>
      </div>
    </div>
    <ConfirmationModal
      @closeModal="modal.show = false"
      @remove="saveProgress('remove', modal.category, modal.experienceId)"
      :modal="modal"
    />
  </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import Navbar from "@/components/Common/Navbar/Plain";
import ActionBar from "@/components/OnboardingKit/Experiences/Common/AddedExpsActionBar";
import ConfirmationModal from "@/components/Modals/ConfirmationModal.vue";
import slugsConfig from "@/common/slugs.config";
import Draggable from "vue3-draggable";

export default {
  name: "ObkAddedExps",
  components: {
    Navbar,
    ActionBar,
    Multiselect,
    Draggable,
    ConfirmationModal,
  },
  created() {
    ApiService.get(apiResource.onboardingKit.addedExperiences, {
      onboarding_kit_id: this.onboarding_kit_id,
    })
      .then(({ data }) => {
        this.experiences = data.data.experiences;
        this.kitName = data.data.kit_name;
        this.deliveryTime = data.data.delivery_time;
        this.deliveryTimes = data.data.exp_delivery_times;
        this.candidateExps = this.getExperiencesForCategory(
          slugsConfig.expJourneys.candidateExperiences
        );
        this.fdawExps = this.getExperiencesForCategory(
          slugsConfig.expJourneys.fdawExperiences
        );
        this.employeeExps = this.getExperiencesForCategory(
          slugsConfig.expJourneys.employeeExperiences
        );
        this.employeeExps.forEach((exp) => {
          if (exp.is_survey) {
            if (!this.scheduledExps) {
              this.scheduledExps = [];
            }
            this.scheduledExps.push(exp);
            this.employeeExps.splice(
              this.employeeExps.findIndex((a) => a.id === exp.id),
              1
            );
          }
        });
      })
      .catch(() => {});
  },
  data() {
    return {
      kitName: null,
      experiences: null,
      deliveryTime: "",
      deliveryTimes: null,
      onboarding_kit_id: this.$route.params.onboarding_kit_id,
      slugsConfig,
      candidateExps: null,
      fdawExps: null,
      employeeExps: null,
      scheduledExps: null,
      modal: {
        show: false,
        heading: "Remove experience",
        question: "",
        action: "remove",
        showLottie: true,
        lottiePath: "lotties/warning.json",
        category: null,
        experienceId: null,
      },
      errorMsg: null,
      successMsg: null,
      loading: false,
    };
  },
  methods: {
    getExperiencesForCategory(category) {
      const categoryDetails = this.experiences.filter(
        (j) => j.slug === category
      )[0];
      if (categoryDetails) {
        return categoryDetails.experiences;
      } else {
        return null;
      }
    },
    showRemoveModal(category, categoryName, experienceId) {
      this.modal.category = category;
      this.modal.experienceId = experienceId;
      this.modal.question =
        "Are you sure you want to remove this experience from " +
        categoryName +
        "?";
      this.modal.show = true;
    },
    removeExpFromKit(experienceId, category) {
      ApiService.post(apiResource.onboardingKit.removeExperienceFromCategory, {
        onboarding_kit_id: this.onboarding_kit_id,
        experience_id: experienceId,
        category: category,
      })
        .then(({ data }) => {
          this.success = data.msg;
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        })
        .catch((error) => {
          this.error = error.response.data.msg;
        });
    },
    redirectToExpForm(experienceId) {
      this.$router.push({
        name: "OBKitExperienceForm",
        params: {
          experience_id: experienceId,
          onboarding_kit_id: this.onboarding_kit_id,
        },
      });
    },
    saveProgress(method, category, experienceId) {
      ApiService.post(apiResource.onboardingKit.saveAddedExpsProgress, {
        onboarding_kit_id: this.onboarding_kit_id,
        delivery_time_id: this.deliveryTime,
        candidate_exps: this.candidateExps,
        fdaw_exps: this.fdawExps,
        employee_exps: this.employeeExps,
      })
        .then(() => {
          if (method == "edit") {
            this.redirectToExpForm(experienceId);
          } else if (method == "remove") {
            this.removeExpFromKit(experienceId, category);
          } else if (method == "save") {
            this.saveObk();
          }
        })
        .catch(() => {});
    },
    saveKit() {
      if (!this.kitName) {
        this.errorMsg = "Please set a name for the kit.";
        this.$refs.expActionBar.updateSuccessErrorMsgs(
          this.successMsg,
          this.errorMsg
        );
      } else if (!this.deliveryTime) {
        this.errorMsg = "Please set a delivery time.";
        this.$refs.expActionBar.updateSuccessErrorMsgs(
          this.successMsg,
          this.errorMsg
        );
      } else {
        this.saveProgress("save", "-1", "-1");
      }
    },
    saveObk() {
      this.loading = true;
      ApiService.post(apiResource.onboardingKit.save, {
        onboarding_kit_id: this.onboarding_kit_id,
      })
        .then(({ data }) => {
          this.successMsg = data.msg;
          this.$refs.expActionBar.updateSuccessErrorMsgs(
            this.successMsg,
            this.errorMsg
          );
          this.loading = false;
          setTimeout(() => {
            this.$router.replace({
              name: "CreateOnboardingKitSuccess",
            });
          }, 2000);
        })
        .catch((error) => {
          this.loading = false;
          this.error = error.response.data.msg;
        });
    },
  },
};
</script>

<style scoped lang="postcss"></style>
