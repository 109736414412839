<template>
  <div id="kitNameWrapper" class="flex gap-2 items-center pr-7 pt-4">
    <div
      id="kitName"
      contenteditable="true"
      data-placeholder="Onboarding Kit Name"
      @input="saveKitName"
      @keydown.enter.prevent="$event.target.blur()"
      class="min-w-40 text-2xl font-bold text-black p-2"
      @blur="endKitNameEdit"
      @focus="beginKitNameEdit"
    >
      {{ nameDetails.kitName }}
    </div>
    <button
      id="editKitNameButton"
      @click.prevent="focusOnKitName"
      class="text-black"
    >
      <svg
        fill="none"
        height="24"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <polygon points="14 2 18 6 7 17 3 17 3 13 14 2" />
        <line x1="3" x2="21" y1="22" y2="22" />
      </svg>
    </button>
    <button
      id="blurKitNameButton"
      @click.prevent="blurKitName"
      class="text-black hidden"
    >
      <svg
        enable-background="new 0 0 96 96"
        height="28"
        id="square_check"
        version="1.1"
        viewBox="0 0 96 96"
        xml:space="preserve"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <path
          d="M80,4H16C9.37,4,4,9.37,4,16v64c0,6.63,5.37,12,12,12h64c6.63,0,12-5.37,12-12V16C92,9.37,86.63,4,80,4z M84,80  c0,2.21-1.79,4-4,4H16c-2.21,0-4-1.79-4-4V16c0-2.21,1.79-4,4-4h64c2.21,0,4,1.79,4,4V80z"
        />
        <path
          d="M66.385,35.272c-1.562-1.562-4.095-1.562-5.656,0L43.757,52.243l-8.485-8.485c-1.562-1.562-4.095-1.562-5.657,0  c-1.562,1.562-1.562,4.095,0,5.657l11.312,11.312c1.562,1.562,4.095,1.562,5.657,0l19.799-19.799  C67.947,39.367,67.947,36.835,66.385,35.272z"
        />
      </svg>
    </button>
    <div
      v-show="nameDetails.nameErrMsg"
      class="text-red-500 place-self-end p-3"
    >
      <p class="text-md font-semibold">{{ nameDetails.nameErrMsg }}</p>
    </div>
    <div
      v-if="nameDetails.nameSuccessMsg"
      class="success text-green-500 place-self-end p-3"
    >
      <p class="text-md font-semibold">
        {{ nameDetails.nameSuccessMsg }}
      </p>
    </div>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";

export default {
  props: {
    kit_name: { type: String, default: () => "" },
    onboarding_kit_id: { type: String, required: true },
  },
  created() {},
  data() {
    return {
      nameDetails: {
        kitName: this.kit_name,
        kitNameChanged: false,
        nameErrMsg: null,
        nameSuccessMsg: null,
      },
    };
  },
  methods: {
    beginKitNameEdit() {
      this.showSaveButton();
    },
    saveKitName(e) {
      if (this.nameDetails.kitName != e.target.innerText) {
        this.nameDetails.kitNameChanged = true;
      }
      this.nameDetails.kitName = e.target.innerText;
    },
    endKitNameEdit() {
      this.showEditButton();
      let kitNameElement = document.querySelector("#kitName");
      kitNameElement.blur();
      if (this.nameDetails.kitNameChanged) {
        this.nameDetails.kitNameChanged = false;
        if (this.nameDetails.kitName.trim().length == 0) {
          this.nameDetails.nameErrMsg = "Please give a valid kit name";
        } else {
          //call api and check kit name
          ApiService.post(apiResource.onboardingKit.updateName, {
            onboarding_kit_id: this.onboarding_kit_id,
            name: this.nameDetails.kitName,
          })
            .then((data) => {
              this.nameDetails.nameErrMsg = null;
              this.nameDetails.nameSuccessMsg = "Saved successfully!";
              this.$router.replace({
                name: "CreateOnboardingKitCatalog",
                params: {
                  tag: "all",
                  onboarding_kit_id: data.data.data.onboarding_kit_id,
                },
              });
            })
            .catch((error) => {
              this.nameDetails.nameSuccessMsg = null;
              this.nameDetails.nameErrMsg = error.response.data.msg;
            });
        }
      }
    },
    focusOnKitName() {
      let kitNameElement = document.querySelector("#kitName");
      kitNameElement.focus();
      var range = document.createRange();
      var sel = window.getSelection();

      range.setStart(kitNameElement.childNodes[2], 5);
      range.collapse(true);

      sel.removeAllRanges();
      sel.addRange(range);
      this.showSaveButton();
    },
    showEditButton() {
      let editKitNameButton = document.querySelector("#editKitNameButton");
      let blurKitNameButton = document.querySelector("#blurKitNameButton");

      editKitNameButton.classList.remove("hidden");
      blurKitNameButton.classList.add("hidden");
    },
    showSaveButton() {
      let editKitNameButton = document.querySelector("#editKitNameButton");
      let blurKitNameButton = document.querySelector("#blurKitNameButton");

      editKitNameButton.classList.add("hidden");
      blurKitNameButton.classList.remove("hidden");
    },
  },
};
</script>

<style scoped lang="postcss">
#kitName:empty:before {
  content: attr(data-placeholder);
  color: #939393;
}
</style>
